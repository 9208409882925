.navbar {
  width: rem(80px);
  height: rem(750px);
  padding: var(--mantine-spacing-md);
  display: flex;
  flex-direction: column;
  background-color: var(--mantine-color-red-filled);
}

.navbarMain {
  flex: 1;
  margin-top: rem(50px);
}

.link {
  width: rem(50px);
  height: rem(50px);
  padding: 10px;
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-white);

  &:hover {
    background-color: var(--mantine-color-red-7);
  }

  &[data-active] {
    &,
    &:hover {
      box-shadow: var(--mantine-shadow-sm);
      background-color: var(--mantine-color-white);
      color: var(--mantine-color-blue-6);
    }
  }
}